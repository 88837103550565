<div class="outerBox" [ngClass]="class">
  <div
    *ngIf="!noHeader"
    class="d-flex boxHeader justify-content-between align-items-center"
  >
    <div class="d-flex align-items-center">
      <h2 *ngIf="!filters" class="text-truncate">
        <ng-content select=".heading"></ng-content>
      </h2>
      <div *ngIf="downloadSection" class="download-section">
        <ng-content select=".download"></ng-content>
      </div>
    </div>
    <div *ngIf="filters" class="filters w-100">
      <ng-content select=".filters"></ng-content>
    </div>
    <div
      *ngIf="headingAction"
      class="headingAction"
      [class.headerSearch]="headerSearch"
    >
      <ng-content select=".headingAction"></ng-content>
    </div>
  </div>
  <div
    class="innerBox"
    [class.no-padding]="noPadding"
    [class.no-header]="noHeader"
    [class.no-scrollbar]="noScrollBar"
    [class.no-scroll]="noScroll"
    [class.tableContainer]="tableContainer"
  >
    <app-dc-loading
      *ngIf="loading"
      [loadingText]="loadingText"
    ></app-dc-loading>
    <ng-content></ng-content>
  </div>
</div>
