<label *ngIf="label" [for]="id">{{ label }}</label>
<div class="position-relative">
  <input
    [class]="'form-control input ' + (darkMode === true ? 'dark' : '')"
    [formControl]="control"
    [placeholder]="placeholder == undefined ? '' : placeholder"
    [readonly]="readonly"
    [type]="type"
    autocomplete="off"
    [id]="id"
    [style]="validation ? 'padding-right:40px' : ''"
  />

  <app-dc-control-validation
    *ngIf="control.touched && validation"
    [status]="control.invalid ? 'invalid' : 'valid'"
    class="{{
      control.hasError('required') && control.touched ? 'required' : ''
    }}"
    body="{{ control.hasError('required') ? 'Required field' : '' }}{{
      control.hasError('maxlength') ? 'Max length exceeded' : ''
    }}{{ control.hasError('MnemonicTaken') ? control.errors.message : '' }}"
  ></app-dc-control-validation>
</div>
