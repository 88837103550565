import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DcTableComponent } from './dc-table/dc-table.component';
import { DcModalComponent } from './dc-modal/dc-modal.component';
import { DcSelectComponent } from './dc-select/dc-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DcPopoverComponent } from './dc-popover/dc-popover.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DcDateInputComponent } from './dc-date-input/dc-date-input.component';
import { NgbDateGBParserFormatter } from './dc-date-input/ngb-date-gb-parser-formatter';
import { DcAlertComponent } from './dc-alert/dc-alert.component';
import { DcInputComponent } from './dc-input/dc-input.component';
import { DcCheckboxComponent } from './dc-checkbox/dc-checkbox.component';
import { DcRadioComponent } from './dc-radio/dc-radio.component';
import { DcTextareaComponent } from './dc-textarea/dc-textarea.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DcTableSearchComponent } from './dc-table/dc-table-search.component';
import { NgxResizeWatcherDirective } from './dc-table/ngx-resize-watcher.directive';
import { DcPaneComponent } from './dc-pane/dc-pane.component';
import { DcHeaderComponent } from './dc-header/dc-header.component';
import { DcProgressBarComponent } from './dc-progress-bar/dc-progress-bar.component';
import { DcNavLinkComponent } from './dc-nav-link/dc-nav-link.component';
import { DcCompanyInfoHeaderComponent } from './dc-company-info-header/dc-company-info-header.component';
import { DcCompanyRequestCardComponent } from './dc-company-request-card/dc-company-request-card.component';
import { DcLoadingComponent } from './dc-loading/dc-loading.component';
import { DcDownloadCsvComponent } from './dc-download-csv/dc-download-csv.component';
import { DcSearchComponent } from './dc-search/dc-search.component';
import { DcDateInputRangeComponent } from './dc-date-input-range/dc-date-input-range.component';
import { DcAddTagsComponent } from './dc-add-tags/dc-add-tags.component';
import { DcControlValidationComponent } from './dc-control-validation/dc-control-validation.component';
import { DcMonacoStandaloneComponent } from './dc-monaco-standalone/dc-monaco-standalone.component';
import { DcMonacoDiffComponent } from './dc-monaco-diff/dc-monaco-diff.component';

@NgModule({
  declarations: [
    DcTableComponent,
    DcModalComponent,
    DcSelectComponent,
    DcPopoverComponent,
    DcDateInputComponent,
    DcAlertComponent,
    DcInputComponent,
    DcCheckboxComponent,
    DcRadioComponent,
    DcTextareaComponent,
    DcTableSearchComponent,
    NgxResizeWatcherDirective,
    DcPaneComponent,
    DcHeaderComponent,
    DcProgressBarComponent,
    DcNavLinkComponent,
    DcCompanyInfoHeaderComponent,
    DcCompanyRequestCardComponent,
    DcLoadingComponent,
    DcDownloadCsvComponent,
    DcSearchComponent,
    DcDateInputRangeComponent,
    DcAddTagsComponent,
    DcControlValidationComponent,
    DcMonacoStandaloneComponent,
    DcMonacoDiffComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgSelectModule,
  ],
  exports: [
    DcTableComponent,
    DcModalComponent,
    DcSelectComponent,
    DcPopoverComponent,
    DcDateInputComponent,
    DcAlertComponent,
    DcInputComponent,
    DcCheckboxComponent,
    DcRadioComponent,
    DcTextareaComponent,
    DcTableSearchComponent,
    DcPaneComponent,
    DcHeaderComponent,
    DcProgressBarComponent,
    DcNavLinkComponent,
    DcCompanyInfoHeaderComponent,
    DcCompanyRequestCardComponent,
    DcLoadingComponent,
    DcDownloadCsvComponent,
    DcSearchComponent,
    DcDateInputRangeComponent,
    DcAddTagsComponent,
    DcMonacoStandaloneComponent,
    DcMonacoDiffComponent,
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateGBParserFormatter },
  ],
})
export class UiKitModule {}
